@import "../../styles/variables.scss";

.bylaws-page {
  background-image: url("../../images/logos/logo-watermark.png");
  background-position: 50% 0;
  z-index: -9999;

  .bylaws-container {
    margin: 150px 0px;

    h1 {
      text-align: center;
      margin: 20px 0px 75px;
    }

    h3 {
      margin: 35px 0px;
    }

    p {
      margin: 0px 0px 20px;
      font-size: 18px;
    }

    ol {
      font-size: 18px;

      li {
        margin: 10px 0px;
      }
    }
  }
}

@media (max-width: $sm-max) {
  .bylaws-page {
    .bylaws-container {
      margin: 150px 40px;

      h3 {
        margin: 35px 0px;
      }

      p {
        margin: 0px 0px 20px;
        font-size: 18px;
      }

      ol {
        font-size: 18px;

        li {
          margin: 10px 0px;
        }
      }
    }
  }
}
