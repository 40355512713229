@import "../../styles/variables.scss";

.sponsors-section {
  max-width: 100vh;

  .header {
    font-size: 82px;
    color: black;
  }

  .sponsor-row {
    margin: auto;

    .sponsor-item {
      margin-bottom: 35px;

      .MuiAspectRatio-content {
        background-color: white;

        img {
          object-fit: contain;
        }
      }
    }
  }
}
