@import "../../styles/variables.scss";

.title-screen {
  padding-top: 10.5rem;
  padding-bottom: 6rem;
  text-align: center;
  color: #fff;
  background-image: url("../../images/backgrounds/black-runner-dark.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 100vh;

  .animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }

  @-webkit-keyframes bounce {
    0%,
    100% {
      -webkit-transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-5px);
    }
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-5px);
    }
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }

  .title-screen-logo {
    width: 25%;
    margin-bottom: 40px;
    height: auto;
  }

  .title-arrow {
    margin-top: 20px;
  }
}

@media (max-width: $md-max) {
  .title-screen {
    margin-top: 0px;

    .title-screen-logo {
      width: 50%;
      margin: 40px 0px;
      height: auto;
    }
  }
}
