@import "../../../../styles/variables.scss";

.add-board-member-modal {
  margin-top: 100px !important;
  width: 500px;

  .form-control {
    background-color: white;
    color: black;
  }

  .form-control:focus {
    background-color: white;
    color: black;
  }

  .image-notice {
    font-size: 12px;
    margin-top: 10px;
    color: var(--neutral-400);
  }
}

@media (max-width: $sm-max) {
  .add-board-member-modal {
    width: 90%;
  }
}
