@import "../../styles/variables.scss";

.about-us {
  background-image: url("../../images/logos/logo-watermark.png");
  background-position: 50% 0;
  z-index: -9999;

  .section-container {
    padding-top: 75px;
    padding-bottom: 125px;

    .header {
      font-size: 82px;
    }

    .description {
      font-size: 18px;
      margin: 50px 0px 0px;
      padding: 0px 150px;
    }

    .photos-container {
      margin-top: 125px;
    }
  }
}

.about-us:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

@media (max-width: $md-max) {
  .about-us {
    .section-container {
      text-align: center;
      padding-top: 75px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;

      .header {
        font-size: 58px;
      }

      .description {
        font-size: 16px !important;
        margin: 0px;
        padding: 25px 15px;
        line-height: 1.25rem;
      }

      .photos-container {
        margin-top: 25px;
      }
    }
  }
}
