:root {
  /**********************************************************
    RUNNING FOR REAL ESTATE DESIGN SYSTEM
  **********************************************************/

  // Purple
  --rfr-purple-100: #e4f3fd;
  --rfr-purple-200: #cae6fc;
  --rfr-purple-300: #aed4f8;
  --rfr-purple-400: #97c1f1;
  --rfr-purple-500: #75a6e8;
  --rfr-purple-600: #5581c7;
  --rfr-purple-700: #3a5fa7;
  --rfr-purple-800: #254286;
  --rfr-purple-900: #162c6f;

  /**********************************************************
  POLIS TECH GENERAL DESIGN SYSTEM
  **********************************************************/

  // System - Neutral (Default, Deleted, Coming Soon, Base, etc)
  --neutral-100: #f3f4f5;
  --neutral-200: #e7eaec;
  --neutral-300: #c1c5c7;
  --neutral-400: #8a8d90;
  --neutral-500: #434547;
  --neutral-600: #30363d;
  --neutral-700: #212833;
  --neutral-800: #151c29;
  --neutral-900: #0c1322;

  // System - Positive (Success, Complete, Added, Purchased, etc)
  --success-100: #e0f8d3;
  --success-200: #bcf1a9;
  --success-300: #87d776;
  --success-400: #55af4d;
  --success-500: #1f7a1f;
  --success-600: #16681d;
  --success-700: #0f571c;
  --success-800: #094619;
  --success-900: #053a18;

  // System - Caution (Pending, Warning, Scheduled, Advisory, etc)
  --warning-100: #fdf3d8;
  --warning-200: #fce4b2;
  --warning-300: #f8cf8b;
  --warning-400: #f2ba6c;
  --warning-500: #ea9a3e;
  --warning-600: #c9792d;
  --warning-700: #a85b1f;
  --warning-800: #874113;
  --warning-900: #702e0b;

  // System - Negative (Errors, Alerts, Failed, Rejected, etc)
  --error-100: #fddfcf;
  --error-200: #fcb8a1;
  --error-300: #f68871;
  --error-400: #ee5a4c;
  --error-500: #e41616;
  --error-600: #c4101f;
  --error-700: #a40b25;
  --error-800: #840727;
  --error-900: #6d0428;
}
