@import "../../styles/variables.scss";

.login-page {
  background-color: black;
  color: white;

  .logo {
    width: 40px;
  }

  .logo-link {
    text-decoration: none;
    color: white;

    span {
      font-size: 18px;
    }
  }

  .btn-link {
    font-weight: $light;
    color: var(--rfr-purple-500) !important;
  }

  label {
    color: white;
  }

  .go-back-arrow {
    padding-right: 5px;
    padding-top: 2px;
  }

  .form-control {
    background-color: black;
    color: white;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.1rem var(--plate-gold-500);
    border-color: var(--plate-gold-500);
    background-color: black;
    color: white;
  }
}
