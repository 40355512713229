@import "../../../styles/variables.scss";

.board-member-card {
  margin-bottom: 25px;

  .contact-info {
    font-size: 14px;
    margin-top: 20px;
    color: var(--neutral-400);

    p {
      margin: 0px 0px 10px 0px;
    }
  }
}

@media (max-width: $sm-max) {
  .board-member-card {
    justify-content: center;
    display: flex;
  }
}
