@import "../../styles/variables.scss";

.get-involved-page {
  background-image: url("../../images/logos/logo-watermark.png");
  background-position: 50% 0;
  z-index: -9999;

  .section-container {
    padding-top: 75px;
    padding-bottom: 125px;

    .header {
      font-size: 82px;
    }

    .sub-header {
      font-size: 58px;
    }

    .description {
      font-size: 18px;
      margin: 50px 0px 0px;
      padding: 0px 150px 50px;
    }

    hr {
      margin-top: 25px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 75px;
      width: 80%;
    }

    img {
      width: 100%;
      margin-bottom: 50px;
    }
  }
}

@media (max-width: $md-max) {
  .get-involved-page {
    .section-container {
      .header {
        font-size: 58px;
      }

      .description {
        font-size: 18px;
        padding: 0px 50px 50px;
      }

      .sub-header {
        font-size: 32px;
      }
    }
  }
}
