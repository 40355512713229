@import "./variables.scss";

body {
  font-family: "Mulish", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

a {
  color: var(--rfr-purple-500);
}

a:hover {
  text-decoration: none;
}

.modal-dialog {
  margin: auto;
  margin-top: 150px;
  max-width: 850px;
}

.btn-link {
  background-color: transparent !important;
  border: 0 !important;
  border-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  color: var(--neutral-700) !important;
}

.btn-link:hover {
  color: dodgerblue !important;
}

.btn-primary {
  color: black !important;
  background-color: var(--rfr-purple-500) !important;
  font-weight: $light !important;
}

.btn-primary:hover {
  color: white !important;
  background-color: var(--rfr-purple-700) !important;
}

// TODO: Remove btn-rfr-primary

.btn-rfr-primary {
  color: white !important;
  border: 1px solid white !important;
  background-color: black !important;
  font-weight: $light !important;
}

.btn-rfr-primary:hover {
  border-color: black !important;
  background-color: var(--neutral-700) !important;
}

.section-black {
  background-color: black !important;
  color: white;
}

.text-deep-gold {
  color: var(--neutral-700) !important;
}

.bg-black {
  background-color: black;
}

.full-screen {
  height: 100vh;
}

.logo-50 {
  width: 50%;
}

.form-error {
  color: #e41616;
  top: 5px;
  position: relative;
  font-size: 16px;
}

.section {
  position: relative;
  padding: 50px 0;
  background-color: #fff;
}

.section-header,
.section-header-blog,
.section-presentation-page {
  padding: 0;
}

.section .parallax,
.section .static-image {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
}

.section-header .container-fluid,
.section-header-blog .container,
.section-presentation-page .container {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
}

.section-header .content,
.section-header-blog .content,
.section-presentation-page .content {
  position: relative;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  vertical-align: center;
  line-height: 1.7;
  position: relative;
  top: 44%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section .title-area {
  max-width: 760px;
  margin: 50px auto 100px;
  display: block;
  padding: 0 15px;
  text-align: center;
}

.section .title-area {
  max-width: 760px;
  margin: 50px auto 100px;
  display: block;
  padding: 0 15px;
  text-align: center;
}

.separator {
  color: #cea575;
  margin: 0 auto 20px;
  max-width: 240px;
  text-align: center;
  position: relative;
}

.separator:before,
.separator:after {
  display: block;
  width: 40%;
  content: " ";
  margin-top: 10px;
  border: 1px solid #dbb658;
}

.separator:before {
  float: left;
}

.separator:after {
  float: right;
}

.seperator-img {
  height: 30px;
  width: 30px;
}
