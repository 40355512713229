@import "../../styles/variables.scss";

.events {
  background-image: url("../../images/logos/logo-watermark.png");
  background-position: 50% 0;
  z-index: -9999;

  .section-container {
    padding-top: 75px;
    padding-bottom: 125px;
    min-height: 100vh;

    .header {
      font-size: 82px;
      margin-bottom: 50px;
    }

    .description {
      margin-top: 50px;
      padding: 0px 150px;
      font-size: 24px;
    }

    .event-image {
      max-width: 350px;
      max-height: 350px;
    }
  }

  .past-events-list {
    font-size: 28px;
    margin: 50px 0px;
    padding: 0px 50px;
  }

  hr {
    margin: 50px 0px;
  }
}

@media (max-width: $md-max) {
  .events {
    .section-container {
      text-align: center;
      padding-top: 75px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;

      .header {
        font-size: 58px;
      }

      .description {
        font-size: 16px !important;
        margin: 0px;
        padding: 25px 15px;
        line-height: 1.25rem;
      }

      .event-image {
        max-width: 250px;
        max-height: 250px;
      }
    }

    .past-events-list {
      font-size: 22px;
      margin: 25px 0px;
      padding: 0px 50px;
      list-style: none;
    }
  }
}
