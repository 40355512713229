@import "../../styles/variables.scss";

.our-vision {
  background-image: url("../../images/logos/logo-watermark.png");
  background-position: 50% 0;
  z-index: -9999;

  .section-container {
    padding-top: 75px;
    padding-bottom: 125px;

    .header {
      font-size: 82px;
    }

    .sub-header {
      font-size: 48px;
      margin-bottom: 75px;
    }

    .description {
      font-size: 18px;
      margin: 50px 0px 0px;
      padding: 0px 150px;
    }

    .video {
      margin-top: 50px;
    }

    hr {
      margin: 75px 0px;
    }

    .principles-container {
      padding: 0px 0px 50px 0px;

      .principle-item {
        margin-top: 50px;
        margin-bottom: 50px;

        .principle-item-icon {
          color: var(--rfr-purple-500);
        }

        .principle-title {
          font-size: 32px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: $md-max) {
  .our-vision {
    .section-container {
      text-align: center;
      padding-top: 75px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;

      .header {
        font-size: 58px;
      }

      .sub-header {
        font-size: 32px;
        margin-bottom: 25px;
      }

      .description {
        font-size: 18px !important;
        margin: 0px;
        padding: 25px 15px;
        line-height: 1.25rem;
      }

      hr {
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
      }

      .principles-container {
        .principle-item {
          margin-bottom: 45px;
          font-size: 14px;
          padding-left: 50px;
          padding-right: 50px;

          .principle-title {
            font-size: 22px;
          }
        }
      }
    }
  }
}
