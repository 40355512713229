@import "../../styles/variables.scss";

.our-mission {
  background-color: white;

  .section-container {
    margin: 0 auto;
    padding: 175px 0px;

    .section-header {
      font-size: 68px;
      z-index: 2;
    }

    .description {
      font-size: 22px;
      z-index: 2;
      position: relative;
    }

    .our-mission-image {
      margin-left: 50px;
      height: 350px;
    }

    .mission-statement-image-container {
      text-align: right;
      margin-top: -300px !important;
      margin-right: -2% !important;

      .flower-stamp {
        display: inline-block;
      }
    }
  }

  .services {
    .section-header {
      font-size: 72px;
      margin-bottom: 60px;
    }

    .services-container {
      padding: 50px 0px 150px 0px;

      .service-item {
        .service-item-icon {
          color: var(--rfr-purple-500);
        }

        .service-title {
          font-size: 44px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: $md-max) {
  .our-mission {
    .section-container {
      text-align: center;
      padding-top: 100px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 75px;

      .description-container {
        text-align: center;

        .section-header {
          font-size: 48px;
        }

        .description {
          font-size: 14px;
          margin-top: 15px;
          padding-left: 40px;
          padding-right: 40px;
        }

        .mission-statement-image-container {
          margin-top: -325px !important;
          margin-right: 0% !important;

          .flower-stamp {
            transform: scale(0.4);
          }
        }
      }

      .image-container {
        margin: 0 auto;

        img {
          display: block;
          margin-top: 30px;
          margin-left: auto !important;
          margin-right: auto;
          height: 150px !important;
        }
      }
    }

    hr {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }

    .services {
      padding-top: 75px;

      .section-header {
        font-size: 64px;
      }

      .services-container {
        .service-item {
          margin-bottom: 45px;
          font-size: 16px;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
  }
}
