@import "../../styles/variables.scss";

.cta-parallax {
  background-image: url("../../images/backgrounds/marathon-bg-dark.jpg");
  min-height: 300px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px;
  color: white;

  .cta-text {
    font-size: 52px;
    margin-bottom: 40px;
  }

  .cta-link {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    display: inline-block;
    border: 2px solid white;
    font-size: 42px;
    padding: 15px 55px;
  }

  .cta-link:hover {
    background-color: var(--rfr-purple-500);
  }
}

@media (max-width: $md-max) {
  .cta-parallax {
    .cta-text {
      font-size: 32px;
    }

    .cta-link {
      font-size: 28px;
    }
  }
}

@media (max-width: $sm-max) {
  .cta-parallax {
    .cta-text {
      font-size: 26px;
    }

    .cta-link {
      font-size: 22px;
    }
  }
}
